body,html{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: table;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

body {
    font-family: "Lato", sans-serif;
}

.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #d3effb;
    overflow-x: hidden;
    padding-top: 0px;
}

.sidenav .nav_button {
    border: 0;
    margin-bottom: 30px;
    padding: 10px 0px 10px 0px;
    text-decoration: none;
    font-size: 25px;
    color: #2064af;
    display: block;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
}

/* .sidenav .current{
    border:0;
    margin:0;
    padding: 6px 6px 6px 32px;
    text-decoration: none;
    background-color: #2398F5;
    font-size: 25px;
    color: white;
    display: block;
} */


.sidenav .nav_button:hover, .current.nav_button{
    color: #f1f1f1;
    background-color: #2064af;
}

.sidenav img{
    width:100px;
    margin-top: 20px;
    margin-bottom: 75px;
}

.sidenav .logout_button_div{
    position: absolute;
    width:100%;
    bottom: 0;
    border:0;
    margin:0px 0px 20px 0px;
    padding: 0;
}

.sidenav .logout_button{
    width:100%;
    height: 50px;
    background-color: #2064af;
    opacity: 0.6;
    color: #cccccc;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    font-size: 25px;
}
.sidenav .logout_button:hover{
    opacity: 1;
    color: #f1f1f1;
}

.sidenav input{
    border:0;
    margin:0;
    padding: 6px 0px 6px 0px;
    background-color: #BE1E2D;
    text-decoration: none;
    font-size: 25px;
    color: #94918F;
    width: 100%;
}

.h4_menu{
    margin-top:25px;
}

.main {
    margin-left: 250px; /* Same as the width of the sidenav */
    padding:30px;
}

.main textarea{
    resize: none;
}

.collapsible {
    background-color: #2064af;
    opacity: 0.7;
    color: white;
    padding: 10px 10px 10px 20px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.collapsible:hover {
    opacity: 1;
}
  
.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}

.top_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.fa-calendar-alt{
    color: #cccccc;
    cursor: pointer;
}

.fa-calendar-alt:hover{
    color: white;
}

.fa-pen-square{
    color: #cccccc;
    cursor: pointer;
}

.fa-pen-square:hover{
    color: white;
}

.fa-trash-alt{
    color: #cccccc;
    cursor: pointer;
}

.fa-trash-alt:hover{
    color: white;
}

.fa-plus-circle{
    opacity: 0.6;
    color: green;
    cursor: pointer;
}

.fa-plus-circle:hover{
    opacity: 1;
    color: green;
}

.buttons_manager{
    width: 100px;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

.buttons_manager_3{
    width: 150px;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

.editor{
    margin-bottom: 40px;
}

.button_no_style{
    all: unset;
}

.confirmation{
    width: 500px;
}

.confirmation_wrapper{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

h1{
    margin: 0px;
}

.contact_div{
    margin: 30px;
}

.clocks{
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
}

.contact_button{
    width: 100%
}

.date{
    display: flex;
    justify-content: center;
}